<template>
  <div @touchstart="touchStart" @touchmove="touchMove">
    <div class="app-content">
      <div class="header">
        <Header v-if="$route.path !== '/login'" />
      </div>
      <div class="content">
        <!-- <OrderLists v-if="brandID" /> -->
        <!-- <router-view v-if="brandID" /> -->
        <router-view />
      </div>
      <b-modal
        v-model="isShowModalOverLay"
        no-fade
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <img
            src="@/assets/images/loading.svg"
            alt="loading"
            class="my-2 loading-icon"
          />
          <div class="textc-primary">กรุณารอสักครู่</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Layouts/Header.vue";
import iconFacebook from "@/assets/images/facebook.png";
import iconLine from "@/assets/images/line.png";
import iconInstagram from "@/assets/images/instagram.png";
import iconWeb from "@/assets/images/internet.png";
import { mapGetters } from "vuex";

export default {
  components: {
    Header
  },
  data() {
    return {
      brandId: null,
      config: {},
      forceRefresh: true,
      brandLists: [],
      isOrder: false,
      pathUrl: this.$baseUrl,
      chatChannelList: [
        { id: 0, name: "All Channel", imageUrl: "" },
        { id: 1, name: "Facebook", imageUrl: iconFacebook },
        { id: 2, name: "Line", imageUrl: iconLine },
        { id: 3, name: "Instagram", imageUrl: iconInstagram },
        { id: 4, name: "Web", imageUrl: iconWeb }
      ],
      xDown: null,
      yDown: null
    };
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    this.isOrder = this.$route.meta.isOrder;
    if (this.isOrder == true) {
      this.pathUrl = this.$baseUrlOrder;
    }
    this.brandId = new URL(location.href).searchParams.get("brandID");
    this.getAllBrand(this.brandId);
    if (this.brandId) {
      this.$store.dispatch("setBrand", this.brandId);
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      activePanel: "getActivePanel",
      agentSelected: "getAgentSelected",
      screenSize: "getScreenSize",
      chatModeList: "getChatModeCount"
    })
  },
  mounted() {},
  methods: {
    handleResize() {
      let screenSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      this.$store.dispatch("setScreenSize", screenSize);
    },
    touchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    touchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          console.log("slideRight");
          if (this.activePanel < 3 && this.agentSelected)
            this.$store.dispatch("setActivePanel", this.activePanel + 1);
        } else {
          /* right swipe */
          console.log("slideLeft");
          if (this.activePanel > 1) {
            this.$store.dispatch("setActivePanel", this.activePanel - 1);

            // if (this.activePanel == 1)
            //   this.$store.dispatch("setAgentSelected", false);
          }
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
    async getAllBrand(brandId) {
      var path = "/api/brand/list";
      // if (this.isOrder) {
      path = "/api/admin/brand";
      // }
      await this.$axios.get(this.pathUrl + path).then(async response => {
        if (response.data.result === 1) {
          this.brandLists = response.data.detail;

          if (response.data.detail.length !== 0) {
            await this.$store.dispatch("setBrandLists", response.data.detail);
            this.$store.dispatch("setUserPermission", true);
            if (!brandId) {
              brandId = response.data.detail[0].id;
              await this.$store.dispatch(
                "setBrand",
                response.data.detail[0].id
              );
              // this.getBrandConfig(brandId);
              // this.getPermission(brandId);
              this.$router
                .replace({
                  path: "/",
                  query: { brandID: brandId }
                })
                .catch(err => {
                  console.log("err", err);
                });
            } else if (brandId) {
              if (!this.textValidate(brandId)) {
                this.$store.dispatch("setBrand", response.data.detail[0].id);
                brandId = response.data.detail[0].id;
                this.$router
                  .replace({
                    path: "/",
                    query: { brandID: brandId }
                  })
                  .catch(err => {
                    console.log("err", err);
                  });
              } else {
                var checkBrandFlag = this.brandLists.some(
                  el => el.id === Number(brandId)
                );
                if (!checkBrandFlag) {
                  brandId = response.data.detail[0].id;
                  this.$store.dispatch("setBrand", response.data.detail[0].id);
                  this.$router
                    .replace({
                      path: "/",
                      query: { brandID: brandId }
                    })
                    .catch(err => {
                      console.log("err", err);
                    });
                } else {
                  this.$store.dispatch("setBrand", this.brandId);
                }

                // this.$emit("OrderLists");
              }
              // this.getBrandConfig(brandId);
              // this.getPermission(brandId);
            }
            //this.$emit("OrderLists");
            await this.$store.dispatch("getChatModeListCount");
            this.$store.dispatch("setChatModeLabel");
            if (this.chatModeList.length > 0) {
              await this.$store.dispatch(
                "setChatMode",
                this.chatModeList[0].modeId
              );
            }
          } else {
            if (this.$route.path !== "/AccessDenied") {
              this.$router.push({
                path: "/AccessDenied"
              });
            }
          }
        }
      });
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    textValidate(text) {
      var textReg = /^\d+$/;
      return textReg.test(text);
    },
    getPermission(brandId) {
      this.$axios
        .post(this.pathUrl + "/api/User/Brand/" + brandId + "/Permission")
        .then(response => {
          if (response.data.result === 1) {
            this.$store.dispatch("setPermission", response.data.detail.finance);
          } else {
            alert(response.data.message);
          }
        });
    },
    getBrandConfig(brandId) {
      this.$axios
        .get(this.pathUrl + "/api/brand/" + brandId + "/config")
        .then(response => {
          this.config = response.data.detail;
          //this.$store.dispatch("setPermission", response.data.detail.finance);
          this.$store.dispatch("setBrandLogo", response.data.detail.logo);
          this.$store.dispatch(
            "setTheOneCardFlag",
            response.data.detail.useThe1Card
          );
          this.$store.dispatch("setBrandName", response.data.detail.name);
          this.$store.dispatch(
            "setStoreFlag",
            response.data.detail.isStoreFulfillment
          );
          this.$store.dispatch(
            "setBrandColor",
            response.data.detail.colorThemeMain
          );
        });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style scoped></style>
